import React, { Suspense } from 'react';
import { Card } from 'wix-ui-tpa/cssVars';
import { CartModalDataHooks } from '../dataHooks';
import Text from '../../../Text/Text';
import DateAndTime from '../../DateAndTime/DateAndTime';
import { classes, st } from './ModalContent.st.css';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { FormSelectedSlot } from '../../../../../types/formSelectedSlots';
import { ServiceData } from '../../../../../utils/state/types';
import { getServiceSlotIdentifier } from '../../../../../utils';
import EmptyStatePage from '../../EmptyStatePage/EmptyStatePage';
import { ExperimentsConsts } from '../../../../../consts/experiments';
import UpsellPlugin from '../../upsellPlugin';

const ServiceImage = React.lazy(
  () => import('../../ServiceImage/ServiceImage'),
);

export interface ModalContentProps {
  serviceData: ServiceData;
  formSelectedSlot: FormSelectedSlot;
  dateRegionalSettingsLocale: string;
}

export const ModalContent: React.FC<ModalContentProps> = ({
  serviceData,
  formSelectedSlot,
  dateRegionalSettingsLocale,
}) => {
  const { isRTL } = useEnvironment();
  const { experiments } = useExperiments();

  const isAdditionalTimeSlotsInFormPluginEnabled = experiments.enabled(
    ExperimentsConsts.AdditionalTimeSlotsInFormPlugin,
  );

  return (
    <>
      {formSelectedSlot.nestedSlots.map((slot) => {
        const serviceSlotsKey = getServiceSlotIdentifier(slot);
        const service = serviceData.slotServices[serviceSlotsKey].service;
        const serviceImage =
          serviceData.slotServices[serviceSlotsKey]?.service.images?.[0];
        return (
          <div>
            <Suspense fallback={<EmptyStatePage isProcessing />}>
              <Card
                key={serviceSlotsKey}
                data-hook={CartModalDataHooks.CARD}
                className={st(classes.card, { rtl: isRTL })}
              >
                {serviceImage && (
                  <Card.Container className={classes.left}>
                    <ServiceImage
                      {...serviceImage}
                      dataHook={CartModalDataHooks.CARD_IMAGE}
                    />
                  </Card.Container>
                )}
                <Card.Container className={classes.right}>
                  <div>
                    <Text isBlackOnly data-hook={CartModalDataHooks.CARD_TITLE}>
                      {service.name}
                    </Text>
                    <DateAndTime
                      totalNumberOfSessions={1}
                      dateRegionalSettingsLocale={dateRegionalSettingsLocale}
                      slot={slot}
                      service={service}
                      isBlackOnly
                    />
                  </div>
                </Card.Container>
              </Card>

              {isAdditionalTimeSlotsInFormPluginEnabled && <UpsellPlugin />}
            </Suspense>
          </div>
        );
      })}
    </>
  );
};
